<template>
    <div>
        <div class="container "> 
            <br />
               <div class=" col-12" style="text-align:left">
                <div v-if="this.event" class="box" >
                <h2><i @click="$router.push('/dashboard')" style="cursor:pointer; " class="fas fa-chevron-circle-left"></i> VS: {{ this.event.opponent }}</h2>
                <p>Date: {{ new Date(event.date).toLocaleDateString()}}</p>
                </div>
                 </div>
                   <br />
            <div v-if="event" class="row" >
                <div class="col-6">
                    <div class="box"><h4>Add from SeasonCast</h4>
                    <p>Pull any broadcast from SeasonCast. After added, you can create & tag sub-clips!</p>
                    <autocomplete :search="searchSeasonCast" @submit="pullRecordings"></autocomplete><br />
        <table id="customers">
  <tr>
    <th>Date</th>
    <th>Title</th>
    <th></th>
  </tr>
  <tr v-for="(recording ,index) in recordings" v-bind:key="index" >
    <td>{{ new Date(recording.onPub).toLocaleDateString()}}</td>
   <td>{{ recording.title }}</td>
   <td><button class="btn btn-success" @click="addSCRecording(recording)">Add <i class="fas fa-chevron-circle-right"></i></button></td>
  </tr>
  
</table>
                         </div>
                </div>
                  <div class="col-6">
                    <div class="box"><h4>Upload from device</h4>
                      <p>Add any amount of video clips to this event.</p>
                        <div class="form-group">
    <label for="exampleFormControlFile1">Select MP4 Clip(s)</label><br />
    <input type="file"  ref="file_select" class="form-control" id="exampleFormControlFile1" @change="previewFiles; $forceUpdate()" multiple>
  </div>
  <div v-if="$refs.file_select">
      <br />
        <button class="btn btn-primary" @click="uploadAll" :disabled="upload_progress" v-if="$refs.file_select.files.length > 0" >Upload All Recordings</button><br />
 <br />
  {{ upload_progress ? "Progress: "+upload_progress: ""}}
  <div v-for="(file ,index) in $refs.file_select.files" v-bind:key="index">
      <br />
      <hr />
     
  #{{index + 1 }} {{ file.name }} 
  </div> 
  

  </div>
                         </div>
                </div>
                  </div>
                  <br />
         
                <br />
                 <div hidden class="box" >
                <h4 style="text-align:left;">Game Clips</h4>

                <hr />

               
          
           <!-- <table id="customers">
  <tr>
    <th>#</th>
    <th>Name</th>
    <th>Source</th>
  </tr>
  <tr v-for="(clip ,index) in event.clips" v-bind:key="index" >
    <td>{{ index + 1}}</td>
   <td>{{ clip }}</td>
   <td>{{ clip.source.type }}</td>
  </tr>
  
</table> -->

               
         
              </div>
          
             </div>
       
    </div>
</template>
<script>
import firebase from "firebase";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import Swal from 'sweetalert2'
import axios from "axios";

require("firebase/firestore");
var db = firebase.firestore();

export default {
     name: "AddClips",
       components: {
    Autocomplete
  },
       data() {
    return {
      user: null,
      event: undefined,
      recordings: [],
      upload_progress: undefined,
      seasoncast_channel: undefined
    };
  },
    mounted: async function (){
   
      var docRef = db.collection("Events").doc(this.$route.params.event_id);

 var doc = await docRef.get()

    if (doc.exists) {
        console.log("Document data:", doc.data());
        this.event = doc.data()
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }

    },
    methods: {
         server_update_event(){
     db.collection("Events").doc(this.$route.params.event_id).update(this.event)
      },
        addSCRecording: async function (recording){
          await this.getSeasonCastURL(recording, {})


        },
      previewFiles() {
           
      
      },
     async getSeasonCastURL(recording, additional){
                    this.reason = undefined
             var response = await axios.post('https://app.seasoncast.com/v2/viewer/watch/recording', {
    channel_id: this.seasoncast_channel,
    recording_id: recording.session, ...additional
  })

  if(!response.data.success){
    
console.log(response.data)

switch (response.data.reason) {
  case "failure_auth_cp":
        var { value: communityPassword } = await Swal.fire({
  title: 'Enter channel commpuntiy password',
  input: 'text',
  inputLabel: additional.community_password ? 'Incorrect. Try Again.':'Community Password:',
  showCancelButton: true
})

if (communityPassword) {
  additional.community_password = communityPassword
  this.getSeasonCastURL(recording,additional)
}
    break;
  case "failure_auth_seasonpass":
     Swal.fire("GameTaper currently does not support channels with SeasonPass enabled. ")
    break;
  default:
    Swal.fire(response.data.reason)
    break;
}


    return
  }

  this.event.clips.push({source: {type:"seasoncast", username: this.seasoncast_channel, recording_id: recording.session, watch_url: response.data.result.watch_url }, tags: {name: "Full Broadcast - "+recording.title}})
this.server_update_event()
        this.$router.push('/watch/'+this.$route.params.event_id)

      },
      uploadAll(){
         this.upload(0)
      },
      upload(id){

      this.upload_progress = ""+(id + 1) + "/"+this.$refs.file_select.files.length +" - " + this.$refs.file_select.files[id].name
     

      var storageRef = firebase.storage().ref();
var name = Math.random().toString(36).substring(7)
// Create a reference to 'mountains.jpg'
var mountainsRef = storageRef.child("clips/"+this.$route.params.event_id+"/"+name+'.mp4');


let uploadTask = mountainsRef.put(this.$refs.file_select.files[id])

uploadTask.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
          this.upload_progress = ""+(id + 1) + "/"+ this.$refs.file_select.files.length + " - " + this.$refs.file_select.files[id].name + " "+ progress.toFixed(2) +"%"

    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('Upload is running');
        break;
    }
  }, 
  () => {
    // Handle unsuccessful uploads
    this.upload_progress = " error"
  }, 
  () => {
    this.event.clips.push({source: {type:"gametaper", region:1, recording_id: name }, tags: {name: "Clip"}})
      this.server_update_event()
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    if (this.$refs.file_select.files.length-1 > id){
     this.upload(id + 1)
     
    }else{
        this.upload_progress = undefined
        this.$router.push('/watch/'+this.$route.params.event_id)
    }

 
  });


   },
        pullRecordings: async function(username){
            this.seasoncast_channel = username
              let response = await fetch("https://app.seasoncast.com/v2/channel/recordings", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({	"channel_id": username,
	"start":0,
	"end":100,
	"stats":true})
  })
      const content = await response.json();

  this.recordings = content.recordings
        
        },
        searchSeasonCast: async function(input){
            let response = await fetch("https://app.seasoncast.com/v2/global/search", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({query: input})
  })
      const content = await response.json();

  return content.results.map((value) => { return value.id})
        },
    logOut() {
      firebase.auth().signOut().then(() => {
        firebase.auth().onAuthStateChanged(() => {
          this.$router.push('/')
        })
      })
    }
  }
}
</script>
<style>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}
</style>